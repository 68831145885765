<template>
	<li class="reviews-card">
		<div class="reviews-card__wrapper">
			<!-- <div class="userpic"> -->
			<!-- <img
								src="~@/assets/images/for-markup/landlord1-full.jpg"
								alt="Изображение пользователя"
				/> -->
			<!-- </div> -->
      <div class="userpic">
        <tk-image :src="content.avatar" :alt="content.author_name" />
      </div>
			<div class="description">
				<!-- <p class="username">Тамара Петровна Сергеевгнафва</p> -->
				<p class="reviews-card__date">
					Дата:
					<span>
						{{ getDate(content.date_from) }} –
						{{ getDate(content.date_to) }}
					</span>
				</p>
				<h3 class="reviews-card__room-name">{{ content.place.title }}</h3>
			</div>
		</div>
		<div class="reviews-card__buttons">
			<tk-button
				type="button"
				@click="submit(content.reserve_id)"
			>
				Оценить
			</tk-button>
      <tk-button
        type="button"
        kind="main-brown"
        @click="hide(content.reserve_id)"
      >
        Скрыть
      </tk-button>
		</div>
	</li>
</template>

<script>
import mods from '@/constants/mods'

import { getDateInFormat } from '@/utils/dateTime'

export default {
	name: 'ReviewCard',
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		isGuest() {
			return this.$store.getters.mode === mods.GUEST
		}
	},
	methods: {
		getDate(payload) {
			return getDateInFormat(payload)
		},
		submit(id) {
			const { push } = this.$router
      push({ path: `/profile/reviews/guest/${id}` })
		},
    async hide(id) {
      const response = await this.$api.reviews.hideGuestReview(id)

      if (response?.status) {
        this.$emit('update')
      } else {
        this.$store.commit('showNotification', { type: 'error', text: 'Ошибка скрытия' })
      }
    }
	}
}
</script>
