<template>
	<div class="reviews">
		<div class="reviews__title-wrapper">
			<div class="reviews__title-wrapper--left">
				<h2 class="section-title">{{ unit.title }}</h2>
			</div>
			<div :key="current" class="reviews__title-wrapper--right">
				<template v-for="item in tabTitles" :key="item.key">
					<tk-button
						type="button"
						@click="() => (current = item.key)"
						:class="{ active: current === item.key }"
						kind="title-btn"
					>
						{{ item.toggle }}
					</tk-button>
				</template>
			</div>
		</div>

		<ul :key="current" :class="unit.classContainer">
			<template v-if="result && result.length">
				<template v-for="item in result" :key="item.reserve_id">
					<component @update="update" :content="item" :is="unit.component" />
				</template>
			</template>
			<div v-else class="empty-text">{{ unit.empty }}</div>
		</ul>

		<tk-pagination
			v-if="meta && meta.to"
			:offsetPage="page"
			:content="meta"
			@next="changePage"
		/>
	</div>
</template>

<script>
import ReviewGuestToLandlord from '@/components/cards/ReviewGuestToLandlord'
import ReviewLandlordToGuest from '@/components/cards/ReviewLandlordToGuest'
import ReviewCard from '@/components/cards/ReviewCard'
import ReviewCardGuest from "@/components/cards/ReviewCardGuest";
import mods from "@/constants/mods";

/**
 * Заголовки меню: ожидаемые, полученные, отправленные (отзывы)
 *
 * @type {{expected: {toggle: string, key: string}, sended: {toggle: string, key: string}, received: {toggle: string, key: string}}}
 */
const tabTitles = {
  expected: {
    key: 'expected',
    toggle: 'Ожидаются',
  },
  sended: {
    key: 'sended',
    toggle: 'Отправленные',
  },
  received: {
    key: 'received',
    toggle: 'Полученные',
  },
}

/**
 * Все возможные состояния: произведение {ожидаемые, полученные, отправленные} X {гостем, хозяином}
 * Так, полученные гостем - это отзывы от всех хозяев, у которых гость когда-либо останавливался, на него.
 * А полученные хозяином - от гостей на все когда-либо предоставленные "теремки".
 * И т.д.
 *
 * @type {{sendedLandlord: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, receivedLandlord: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, expected: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, sended: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, received: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, expectedLandlord: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}}}
 */
const config = {
	expected: {
		key: 'expected',
		classContainer: 'reviews-cards',
		component: 'ReviewCard',
		title: 'Ожидаемые отзывы',
		toggle: 'Ожидаются',
		empty: 'Путешествуйте больше, чтобы оставлять отзывы о хозяевах жилья.'
	},
	sended: {
		key: 'sended',
		classContainer: 'reviews-sended-cards',
		component: 'ReviewGuestToLandlord',
		title: 'Отправленные отзывы',
		toggle: 'Отправленные',
		empty: `Вы пока не оставляли отзывов на хозяев жилья. 

						Возможно, у вас уже есть оповещения оставить отзыв, посмотрите в “Ожидаются”.`
	},
	received: {
		key: 'received',
		classContainer: 'reviews-reseived-cards',
		component: 'ReviewLandlordToGuest',
		title: 'Полученные отзывы',
		toggle: 'Полученные',
		empty: `Вы пока не получали отзывы от хозяев жилья.

						Путешествуйте больше, чтобы получить отзывы и стать самым популярным жильцом.`
	},
  expectedLandlord: {
    key: 'expectedLandlord',
    classContainer: 'reviews-cards',
    component: 'ReviewCardGuest',
    title: 'Ожидаемые отзывы',
    toggle: 'Ожидаются',
    empty: 'Сдавайте чаще, чтобы оставлять отзывы о жильцах.'
  },
  sendedLandlord: {
    key: 'sendedLandlord',
    classContainer: 'reviews-sended-cards',
    component: 'ReviewLandlordToGuest',
    title: 'Отправленные отзывы',
    toggle: 'Отправленные',
    empty: `Вы пока не оставляли отзывов о жильцах.

						Возможно, у вас уже есть оповещения оставить отзыв, посмотрите в “Ожидаются”.`
  },
  receivedLandlord: {
    key: 'receivedLandlord',
    classContainer: 'reviews-reseived-cards',
    component: 'ReviewGuestToLandlord',
    title: 'Полученные отзывы',
    toggle: 'Полученные',
    empty: `Вы пока не получали отзывов от жильцов.

						Сдавайте чаще, чтобы получать отзывы о предоставляемом жилье.`
  }
}

export default {
	components: { ReviewCard, ReviewCardGuest, ReviewGuestToLandlord, ReviewLandlordToGuest },
	watch: {
		page: async function () {
			await this.get()
		},
		current: async function () {
      this.page = 1
      this.getCountAbortController.abort()
			await this.get()
			await this.getCount()
		}
	},
	data() {
		return {
			current: 'expected',
			page: 1,
			result: [],
			meta: null,
			counts: {
				completed: 0,
				upcoming: 0
			},

      getAbortController: new AbortController(),
      getCountAbortController: new AbortController(),
		}
	},
	computed: {
    /**
     * Все возможные состояния: произведение {ожидаемые, полученные, отправленные} X {гостем, хозяином}
     *
     * @returns {{sendedLandlord: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, receivedLandlord: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, expected: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, sended: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, received: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}, expectedLandlord: {classContainer: string, component: string, toggle: string, title: string, key: string, empty: string}}}
     */
		config() {
			return config
		},
    /**
     * Заголовки меню: ожидаемые, полученные, отправленные (отзывы)
     *
     * @returns {{expected: {toggle: string, key: string}, sended: {toggle: string, key: string}, received: {toggle: string, key: string}}}
     */
    tabTitles() {
      return tabTitles
    },
    /**
     * Возвращает имя состояния (config) для правильного отображения компонента
     * с отзывами: произведение {ожидаемые, полученные, отправленные} X {гостем, хозяином}
     * Гость - пустой суффикс
     * Хозяин - суффикс 'Landlord'
     *
     * @returns {*}
     */
		unit() {
      let config;
      if (this.$store.getters.mode === mods.GUEST) {
        config = this.current
      } else {
        config = this.current + 'Landlord'
      }
			return this.config[config]
		},
    component() {
      let result
      if (this.$store.getters.mode === mods.GUEST) {
        result = 'ReviewGuestToLandlord'
      } else {
        result = 'ReviewLandlordToGuest'
      }
      return result
    },
	},
	methods: {
    /**
     * Получаемый отзывы в зависимости от состояния
     * @link unit
     *
     * @returns {Promise<void>}
     */
		async get() {
      this.getAbortController.abort()
      this.getAbortController = new AbortController()

			this.result = []
      let response

      // Получаем комментарии в зависимости от того
      // гость мы или хозяин
      if (this.$store.getters.mode === mods.GUEST) {
        response = await this.$api.reviews.get({
          type: this.current,
          page: this.page
        }, {controller: this.getAbortController})
      } else {
        response = await this.$api.reviews.getOwner({
          type: this.current,
          page: this.page
        }, {controller: this.getAbortController})
      }

      // Если ответ на запрос с комментариями пришел - добавляем в data()
			if (response && response.status) {
				this.meta = response.response.meta
				this.result = response.response.items
			} else {
        return
      }

      let userIds = []
      let users = []
      try {
        // Получаем id авторов комментариев, чтобы позже подгрузить их аватарки.
        // Если мы Гость и просматриваем "ожидаемые комментарии", то должны сначала
        // получить список объявлений теремков по броням, затем их акторов (хозяев) и взять id.
        // Иначе все несколько проще - достаточно забрать id из авторов из комментариев.
        if (this.$store.getters.mode === mods.GUEST && this.current === 'expected') {
          this.result.map((r) => r.user_id = r.reviewer_user_id)
          userIds = [...new Set(this.result.map((r) => r.user_id))]
        } else if (this.current === 'sended') {
          this.result.map((r) => r.user_id = r.reviewer_user_id)
          userIds = [...new Set(this.result.map((r) => r.user_id))]
        } else {
          userIds = [...new Set(this.result.map((r) => r.user_id))]
        }

        // Получаем информацию о пользователях для отображения аватарок и имен
        users = await this.$api.usersList.getNamesAndAvatars(userIds, {controller: this.getAbortController})
      } catch (e) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Ошибка получения данных'
        })
      } finally {
        // в каждый комментарий добавляем отображаемое имя и аватарку автора
        this.result.forEach((r) => {
          r.author_name = users[r.user_id]?.name ?? ''
          r.avatar = users[r.user_id]?.avatar ?? '/images/default-avatar.svg'
        })
      }
		},
		async getCount() {
      this.getCountAbortController = new AbortController()
			const response = await this.$api.reviews.getCount({controller: this.getCountAbortController})

			if (response && response.status) {
				this.counts.completed = response.response.completed
				this.counts.upcoming = response.response.upcoming
			}
		},
		changePage(payload) {
			this.page = payload
		},
		async update() {
			await this.get()
		},
	},
	async beforeMount() {
		await this.get()
		await this.getCount()
	},
  beforeUnmount() {
    this.getAbortController.abort()
    this.getCountAbortController.abort()
  }
}
</script>
