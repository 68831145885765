<template>
	<li class="reviews-sended-card">
		<div class="reviews-sended-card__wrapper">
			<div class="userpic">
        <tk-image :src="content.avatar" :alt="content.author_name" />
			</div>
			<div class="description">
				<p class="date">{{ getDateInFormat(content.created_at) }}</p>
				<p class="username">{{ content.author_name }}</p>
			</div>
		</div>
    <p class="reviews-sended-card__text review-hide" ref="text">
      {{ content.text }}
    </p>
    <div v-if="showMoreBtn" class="read-more-btn" @click="readMore()">
      Читать далее
    </div>
    <RatingList :ratings="content.ratings" />

    <button v-if="isGuest" class="reviews-sended-card__delete" type="button" @click="areYouSure">
      Удалить
    </button>
    <!--
    <button v-else class="reviews-sended-card__delete" type="button" @click="appealReview">
      Обжаловать отзыв
    </button>
    -->
	</li>
</template>

<script>
import mods from "@/constants/mods";
import { getDateInFormat } from "@/utils/dateTime";
import RatingList from "@/components/cards/review/RatingList";

export default {
	name: 'ReviewGuestToLandlord',
  components: { RatingList },
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
  data() {
    return {
      showMoreBtn: false,
    }
  },
	computed: {
    isGuest() {
      return this.$store.getters.mode === mods.GUEST
    }
	},
	methods: {
    readMore() {
      this.$store.commit('showModal', {
        name: 'ReviewModal',
        props: {
          review: this.content,
        }
      })
    },
    areYouSure() {
      this.$store.commit('showModal', {
        name: 'CommonConfirm',
        props: {
          text: `Вы действительно хотите удалить отзыв?`,
          callback: this.deleteCard
        }
      })
    },
		async deleteCard() {
			if (!this.content.id) {
				return
			}

			const response = await this.$api.reviews.del(this.content.id)

			if (response && response.status) {
				this.$store.commit('showNotification', { type: 'success', text: 'Успешно удалено' })

				this.$emit('update')
			} else {
				this.$store.commit('showNotification', { type: 'error', text: 'Ошибка удаления' })
			}
		},
    async appealReview() {

    },
    getDateInFormat,
	},
  /**
   * Т.к. "размер" текстового блока комменатрия изначально ограничен
   * (css overflow), проверяем, вмещается ли весь текст в него
   * если да, то не будем отображать кнопку "показать больше", что изменяет
   * видимость / размеры.
   */
  mounted() {
    this.showMoreBtn = (this.$refs.text.offsetHeight < this.$refs.text.scrollHeight)
  }
}
</script>

<style scoped>
p.review-hide {
  overflow: hidden;
  height: 3.6em;
  line-height: 1.2em;
}
</style>
